import * as React from "react";
import styled from "styled-components";

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export default function LazyAndPreloadImage(props) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    // preload しておく
    const img = new Image();
    img.src = props.src;
    img.crossOrigin = "Anonymous";

    // 読み込めたら img タグ描画する
    img.onload = () => {
      setLoaded(true);
    };
  }, []);

  if (loaded) {
    return (
      <StyledTitles
        src={props.src}
        alt={props.alt}
        style={{
          filter: props.selected === true ? "none" : "grayscale(1)",
        }}
      />
    );
  }

  return <StyledTitles src={"/config/images/10torii.png"} alt={props.alt} />;
}
