import React, { useState, useEffect, useRef } from "react";
import { useAudio } from "./components/useAudio";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  min-width: 6rem;
  border-radius: 10px;
  padding: 0.3rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
`;
export const AudioPlayer = () => {
  const [playing, currentTime, play, pause, jump] = useAudio(
    "https://data.w3-now.com/Morogami.mp3"
  );
  const ref = useRef(null);
  const percentage = (1 - (160 - currentTime) / 160) * 100;
  const adjustPercentage = 100 < percentage ? 100 : percentage;

  return (
    <>
      {/* <p>currenttime: {currentTime}</p> */}
      <div ref={ref}>
        <div className="progress-bar-container">
          <div style={{ width: `${adjustPercentage}%` }}></div>
        </div>
      </div>
      <s.SpacerXSmall />

      <StyledButton onClick={playing ? pause : play}>
        {playing ? "　♪♪♪♪　" : "　🎧 Play　"}
      </StyledButton>

      {/* <button onClick={() => jump(150)}>30sec ▶︎</button> */}
    </>
  );
};
