import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataStake } from "./redux/datastake/datastakeActions";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";
import AudioPlayer from "./components/AudioPlayer";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
// import { AudioPlayer } from "./AudioPlayer";
import Header from "./components/Header";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Member from "./components/Member";
import Faq from "./components/Faq";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import CircleTextLogoLeft from "./components/CircleTextLogoLeft";
import CircleTextLogoRight from "./components/CircleTextLogoRight";
import useMedia from "use-media";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import AlCheckerTable from "./components/AlCheckerTable";
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.6); */
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font: bold 1em "Fira Sans", serif;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function ALCheck() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const datastake = useSelector((state) => state.datastake);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWl, setMintAmountWl] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWl, setmintedWl] = useState(0);
  const [wl, setwl] = useState(false);
  const [lang, setlang] = useState(false);
  const [mintable, setMintable] = useState(0);
  const [canMint, setcanMint] = useState(0);
  const [stakeSelect, setStakeSelect] = useState([]);
  const [unstakeSelect, setUnStakeSelect] = useState([]);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [revealed, setrevealed] = useState(false);
  const [wlMaxMintNum, setwlMaxMintNum] = useState(3);
  const isNotSmp = useMedia({ minWidth: "500px" });
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NSK! check on your Opensea.",
    minting: "Minting NSK....",
    salestartwl: "Presale 1st Stage：11/26(Sat) 20:00〜(JST)",
    salestartwl2: "Presale 2nd Stage：11/26(Sat) 21:00〜(JST)",
    salestartpb: "Public Sale：11/26(Sat) 22:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NSK,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    document.title = "AL CHECKER";
  }, []);

  return (
    <s.Screen>
      <Header config={CONFIG} headerFlag={false} />
      <>
        <s.Container
          flex={1}
          ai={"center"}
          style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
          image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        >
          <ul class="bgtrans">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {true ? (
            <>
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerLarge />
              <MintTitle
                id="mint"
                style={{
                  textAlign: "center",
                }}
              >
                {"ALCHECK"}
              </MintTitle>
              {/* <s.TextDescription
                style={{
                  width: "80%",
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                <AudioPlayer
                  audioFiles={[
                    "https://data.morogami.tk/Morogami.mp3",
                    // Add more audio file URLs as needed
                  ]}
                />
                <AudioPlayer></AudioPlayer>
              </s.TextDescription> */}
              <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
                <s.Container
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    padding: "0 24",
                    borderRadius: 24,
                    border: "4px var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                >
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect(lang));
                          getData();
                        }}
                      >
                        接続(connect)
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <AlCheckerTable wa={blockchain.account} />
                    </>
                  )}
                  <s.SpacerMedium />
                  <s.Container flex={2} jc={"center"} ai={"center"}>
                    <img
                      src="/config/images/mintdata.jpg"
                      style={{ width: "40rem", maxWidth: "100%" }}
                    />
                  </s.Container>
                </s.Container>
                <s.SpacerLarge />
              </ResponsiveWrapper>
            </>
          ) : (
            <></>
          )}
        </s.Container>

        <Footer config={CONFIG} />
      </>
    </s.Screen>
  );
}

export default ALCheck;
