// log
import store from "../store";

const fetchDataStakeRequest = () => {
  return {
    type: "CHECK_DATASTAKE_REQUEST",
  };
};

const fetchDataStakeSuccess = (payload) => {
  return {
    type: "CHECK_DATASTAKE_SUCCESS",
    payload: payload,
  };
};

const fetchDataStakeFailed = (payload) => {
  return {
    type: "CHECK_DATASTAKE_FAILED",
    payload: payload,
  };
};

export const fetchDataStake = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataStakeRequest());
    try {
      let imageURI = [];
      let tokenId = [];
      let walletOfOwner = await store
        .getState()
        .blockchain.stakeSmartContract.methods.walletOfOwner(account)
        .call();
      tokenId = walletOfOwner;
      for (let i = 0; i < walletOfOwner.length; i++) {
        let tokenUri = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenId[i])
          .call();

        // Fetching the JSON data from the tokenUri
        let response = await fetch(tokenUri);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        let jsonData = await response.json();

        // Extracting the "image" field from the JSON data
        let tokenImage = jsonData.image?.replace(
          "Nj-8Yn7guq1nctlNmEbzRunTVB3TNvj6sr5sOJhjHh4",
          "fvHyJPR-sy-gFjc2ptp0XU8dshrXQmd38s8PV0ej50w"
        );

        imageURI[tokenId[i]] = [
          i,
          tokenImage, // This now contains the image URL from the JSON
          "#" + String(tokenId[i]),
          tokenId[i],
        ];
      }
      // console.log(imageURI);
      let balanceOf = await store
        .getState()
        .blockchain.stakeSmartContract.methods.balanceOf(account)
        .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataStakeSuccess({
          imageURI,
          balanceOf,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataStakeFailed("Could not load data from contract."));
    }
  };
};
