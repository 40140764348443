import React, { useEffect, useState } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Grid } from "@material-ui/core";
import Modal from "./components/Modal"; // モーダルコンポーネントのインポート

export const SpacerMediumLarge = styled.div`
  width: 36px;
  height: 36px;
  @media (min-width: 600px) {
    width: 72px;
    height: 72px;
  }
`;
export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  /* border-radius: 24px; */
  /* border: 4px solid #333; */
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChangeLocale = styled.button`
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0);
  padding: 24px;
  /* border-radius: 24px; */
  /* border: 4px solid #333; */
  background-image: ${({ bgImage }) =>
    bgImage
      ? `url(/config/images/introduction/locale/${bgImage}.png)`
      : "none"};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  padding: 2px;
  margin-top: 4px;
  z-index: 0;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;
function Introduction() {
  let charactorList = {
    all: [
      "hokkaido",
      "aomori",
      "miyagi",
      "fukushima",
      "ibaraki",
      "chiba",
      "tokyo",
      "kanagawa",
      "niigata",
      "toyama",
      "yamanashi",
      "shizuoka",
      "osaka",
      "hyogo",
      "nara",
      "wakayama",
      "tottori",
      "shimane",
      "okayama",
      "hiroshima",
      "ehime",
      "kochi",
      "fukuoka",
      "kagoshima",
      "okinawa",
    ],
    hokkaido: ["hokkaido"],
    tohoku: ["aomori", "miyagi", "fukushima"],
    kanto: ["ibaraki", "chiba", "tokyo", "kanagawa"],
    chubu: ["niigata", "toyama", "yamanashi", "shizuoka"],
    kinki: ["osaka", "hyogo", "nara", "wakayama"],
    chugokushikoku: [
      "tottori",
      "shimane",
      "okayama",
      "hiroshima",
      "ehime",
      "kochi",
    ],
    kyushu: ["fukuoka", "kagoshima", "okinawa"],
  };
  let localeList = {
    all: "全て",
    hokkaido: "北海道",
    tohoku: "東北",
    kanto: "関東",
    chubu: "中部",
    kinki: "近畿",
    chugokushikoku: "中国・四国",
    kyushu: "九州",
  };
  const [displayCharactor, setDisplayCharactor] = useState(
    charactorList["all"]
  );
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダルの状態を管理するステート
  const [selectedImage, setSelectedImage] = useState(""); // 選択した画像のURLを格納するステート
  const [selectedCharactor, setSelectedCharactor] = useState(""); // 選択した画像のURLを格納するステート

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const [currentIndex, setCurrentIndex] = useState(null);

  // Define functions to handle swiping to the next and previous items
  const nextItem = () => {
    if (currentIndex < displayCharactor.length - 1) {
      console.log(currentIndex, displayCharactor.length);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevItem = () => {
    if (currentIndex > 0) {
      console.log(currentIndex, displayCharactor.length);
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handle swipe gestures using touch events
  let touchStartX = null;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (touchStartX !== null) {
      const touchEndX = e.changedTouches[0].clientX;
      const deltaX = touchEndX - touchStartX;

      if (deltaX > 50) {
        prevItem(); // Swipe right, go to previous item
      } else if (deltaX < -50) {
        nextItem(); // Swipe left, go to next item
      }

      touchStartX = null;
    }
  };

  // useEffect(() => {
  //   setCurrentIndex(0); // Reset the index when the modal is opened or displayCharactor changes
  // }, [isModalOpen, displayCharactor]);
  useEffect(() => {
    setSelectedImage(
      `/config/images/introduction/${displayCharactor[currentIndex]}.jpg`
    );
    setSelectedCharactor(displayCharactor[currentIndex]);
    // Add keyboard event listeners when the component mounts
    window.addEventListener("keyup", handleKeyUp, false);
    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [currentIndex]);

  const openModal = (imageUrl, charactor, index) => {
    setCurrentIndex(index);
    setSelectedImage(imageUrl);
    setSelectedCharactor(charactor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentIndex(null); // Reset the index when the modal is opened or displayCharactor changes
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
  // Handle keyboard events for left and right arrow keys
  const handleKeyUp = (e) => {
    if (e.key === "ArrowLeft") {
      prevItem();
    } else if (e.key === "ArrowRight") {
      nextItem();
    }
  };
  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      // Disable body scrolling
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // Enable body scrolling
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }

    return () => {
      // Cleanup when the component unmounts
      document.body.style.position = "";
      document.body.style.top = "";
    };
  }, [isModalOpen]);

  const handleAreaClick = (area) => {
    setDisplayCharactor(charactorList[area]);
  };
  return (
    <s.Screen>
      <Header config={CONFIG} headerFlag={false} />

      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "rgba(0, 0, 0,1)",
          filter: isModalOpen ? "blur(5px)" : "none",
        }}
      >
        <SpacerMediumLarge />
        <SpacerMediumLarge />
        <nav>
          <BoxList>
            <Grid container direction="column">
              <Grid item container>
                {Object.keys(charactorList).map((area) => (
                  <Grid xs={6} sm={3}>
                    <ChangeLocale
                      key={area}
                      onClick={() => handleAreaClick(area)}
                      bgImage={area}
                      className={
                        displayCharactor.includes(charactorList[area])
                          ? "active"
                          : ""
                      }
                    >
                      {localeList[area]}
                    </ChangeLocale>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </BoxList>
        </nav>
        <BoxList>
          <Grid container direction="column">
            <Grid item container>
              {displayCharactor.map((charactor, index) => (
                <Grid xs={6} sm={4}>
                  <StyledImg
                    key={`${charactor}_${index}`}
                    src={`/config/images/introduction/${charactor}.jpg`}
                    alt={`${charactor}_${index}`}
                    onClick={() =>
                      openModal(
                        `/config/images/introduction/${charactor}.jpg`,
                        charactor,
                        index
                      )
                    } // 画像をクリックしたらモーダルを開く
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </BoxList>
      </s.Container>
      <Footer config={CONFIG} />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageUrl={selectedImage}
        charactor={selectedCharactor}
        handleTouchStart={handleTouchStart}
        handleTouchEnd={handleTouchEnd}
      />
    </s.Screen>
  );
}

export default Introduction;
