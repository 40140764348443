// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call();

      let stakeAddress = await store.getState().blockchain.stakeSmartContract
        .options.address;

      let isApprovedForAll = await store
        .getState()
        .blockchain.smartContract.methods.isApprovedForAll(
          account,
          stakeAddress
        )
        .call();

      let imageURI = [];
      let tokenId = [];
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.tokensOfOwner(account)
        .call();
      tokenId = walletOfOwner;
      for (let i = 0; i < walletOfOwner.length; i++) {
        let tokenUri = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenId[i])
          .call();

        // Fetching the JSON data from the tokenUri
        let response = await fetch(tokenUri);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}:${tokenUri}`);
        }
        let jsonData = await response.json();

        // Extracting the "image" field from the JSON data
        let tokenImage = jsonData.image?.replace(
          "Nj-8Yn7guq1nctlNmEbzRunTVB3TNvj6sr5sOJhjHh4",
          "fvHyJPR-sy-gFjc2ptp0XU8dshrXQmd38s8PV0ej50w"
        );

        imageURI[tokenId[i]] = [
          i,
          tokenImage,
          "#" + String(tokenId[i]),
          tokenId[i],
        ];
      }

      let ownerAddress = await store
        .getState()
        .blockchain.smartContract.methods.owner()
        .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSubSuccess({
          balanceOf,
          isApprovedForAll,
          imageURI,
          ownerAddress,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
