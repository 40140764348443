import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Stake from "./Stake";
import ALCheck from "./ALCheck";
import Info from "./Info";
import MintPage from "./MintPage";
import Introduction from "./Introduction";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/info" element={<Info />} />
          <Route path="/alcheck" element={<ALCheck />} />
          <Route path="/mint" element={<MintPage />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
