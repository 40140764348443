import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";
import { isMobile } from "react-device-detect";

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  @media (min-width: 600px) {
    padding-bottom: 500px;
  }
  /* @media (min-width: 767px) {
    padding-bottom: 300px;
  } */
  @media (min-width: 884px) {
    padding-bottom: 300px;
  }

  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const LogoImage = styled.img`
  width: 35px;
  :hover {
    -webkit-filter: contrast(10%);
    -moz-filter: contrast(10%);
    -ms-filter: contrast(10%);
    -o-filter: contrast(10%);
    filter: contrast(10%);
    transition: filter 0.7s;
  }
`;
export const MiniImage = styled.img`
  width: 150px;
`;

export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  padding-bottom: 1rem;
  width: 100%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  text-align: center;
`;

export const BackBox = styled.div`
  z-index: 0;
`;
export const FrontBox = styled.div`
  position: absolute;
  width: 100%;
  z-index: 20;
`;

export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: "underline";
`;

export const TextDescription = styled.p`
  color: white;
  text-align: left;
  font-size: 0.8rem;
  font: bold 1em "Fira Sans", serif;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  line-height: 2.6;
  opacity: 0;
`;

export const RoleText = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  font-size: 0.8rem;
  -webkit-user-select: text;
  user-select: text;
  color: red;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  /* line-height: 2.6; */
  opacity: 0;
`;

export const MemberName = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  font-weight: 900;
  text-align: center;
  font-size: 0.9rem;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  /* line-height: 2.9; */
  opacity: 0;
`;

export const MemberNameDesc = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  font-size: 0.6rem;
  -webkit-user-select: none;
  user-select: none;
  @media (min-width: 767px) {
    font-size: 0.8rem;
  }
  line-height: 2.6;
  opacity: 0;
`;

export const TextLink = styled.p`
  color: white;
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledAbout = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledMv1 = styled.img`
  width: 50%;
  @media (min-width: 767px) {
    width: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledMv2 = styled.img`
  width: 50%;
  @media (min-width: 767px) {
    width: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledCapitulo = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 100%;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
  opacity: 0;
`;
export const StyledMember = styled.img`
  width: 80%;
  margin-left: 10%;
  /* @media (min-width: 767px) {
    width: 100%;
  } */
`;

export const AboutTitle = styled.p`
  color: var(--accent-text);
  /* font-family: DelaGothicOne; */
  font: bold 1em "Fira Sans", serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const SpacerXtraLarge = styled.div`
  width: 96px;
  height: 96px;
  @media (min-width: 600px) {
    width: 192px;
    height: 192px;
  }
`;

export const SpacerMediumLarge = styled.div`
  width: 36px;
  height: 36px;
  @media (min-width: 600px) {
    width: 72px;
    height: 72px;
  }
`;
export const StyledAboutName = styled.p`
  color: var(--primary-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  opacity: 0;
  transition: width 0.5s;
  transition: height 0.5s;
`;
function About() {
  const callback = (entries) => {
    //各 entry（IntersectionObserverEntry オブジェクト）に対して
    entries.forEach((entry) => {
      //監視対象の要素が領域内に入った場合の処理
      if (entry.isIntersecting) {
        entry.target.classList.add("act");
      } else {
        // entry.target.classList.remove("act");
      }
    });
  };

  const options = {
    threshold: [0.2, 1],
  };
  const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
  const targets = document.querySelectorAll(".scroll"); //監視対象の要素（target クラスを指定した要素）を全て取得
  targets.forEach((elem) => {
    observer.observe(elem); //observe() に監視対象の要素を指定
  });
  return (
    <BoxList id="about">
      <BackBox>
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={12} sm={4}>
              <StyledMv1 alt={"mv1"} src={"/config/images/mv1.png"} />
            </Grid>
            <Grid xs={12} sm={4}></Grid>
            <Grid xs={12} sm={4}>
              <StyledMv2 alt={"mv2"} src={"/config/images/mv2.png"} />
            </Grid>
          </Grid>
        </Grid>
      </BackBox>
      <FrontBox>
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={12} sm={6}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/5.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={12} sm={6}></Grid>

            <Grid xs={1} sm={3}></Grid>
            <Grid xs={10} sm={6}>
              <TextDescription className={"scroll"}>
                各地に鎮座する神々。
                <br />
                時に共闘し、時に対立、成長し、変化し続けます。
                <br />
                物語の真相を、あなたの目で見届けてください。
              </TextDescription>
              <SpacerXtraLarge />
            </Grid>
            <Grid xs={1} sm={3}></Grid>

            <Grid xs={12} sm={6}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/7.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={12} sm={6}></Grid>

            <Grid xs={1} sm={3}></Grid>
            <Grid xs={10} sm={6}>
              <TextDescription className={"scroll"}>
                日本のどこかにいる神様たちの物語がテーマです。
                <br />
                このプロジェクトを通して日本の魅力を再発見してもらいたい、
                <br />
                そして、キャラクター1人1人を長く愛してもらえるようなIPを目指しています。
              </TextDescription>
              <SpacerXtraLarge />
            </Grid>
            <Grid xs={1} sm={3} id="member-list"></Grid>
            <Grid xs={10} sm={6}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/8.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={2} sm={6}></Grid>
            {/* メンバーリスト一段目 */}
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/kawabatarou.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Founder/Artist</RoleText>
              <MemberName className={"scroll"}>カワバタロウ</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/kawabatarou"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
              <SpacerMediumLarge />
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/KunoKrumm.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Manager/Artist</RoleText>
              <MemberName className={"scroll"}>久埜クルム</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/kuno_nft?s=21"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
              <SpacerMediumLarge />
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/FrickLik.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Engineer</RoleText>
              <MemberName className={"scroll"}>ふりっきー</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/freakyyyy_frick"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
              <SpacerMediumLarge />
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 一段目ここまで */}
            {/* 二段目 */}
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Marketer</RoleText>
              <MemberName className={"scroll"}>Fuggi67</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/FujiSaru67"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Community Manager</RoleText>
              <MemberName className={"scroll"}>CHAN</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/ChanP_jp"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Community Support</RoleText>
              <MemberName className={"scroll"}>ゆきんこ</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={
                    "https://twitter.com/yukinco_NFT?t=jX5lD10-WlFB6u3kUzMR7g&s=09"
                  }
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 二段目ここまで */}

            {/* 楽曲 */}
            <Grid xs={10} sm={6}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/composer.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={2} sm={6}></Grid>

            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>作曲家</RoleText>
              <MemberName className={"scroll"}>SAKUMAMATATA</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/skmmtt"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>

            <Grid xs={4} sm={2}></Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 楽曲ここまで */}

            {/* 三段目 */}
            {/* イラストレーター */}
            <Grid xs={12} sm={9}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/guestArtist.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={0} sm={3}></Grid>

            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>ONIKU kuitai</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/Kuitai_Oniku"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>yuyuharu</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/HimeVerse"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>lemontea</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/lemontea_nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 三段目ここまで */}

            {/* 四段目 */}
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>Hiro</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/HiroOhtaki"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>sashimi</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/ssm_a_u"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>tetra</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/tetra__nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 四段目ここまで */}

            {/* 五段目 */}
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>SAMMY</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/SAMMYNFT_Coll"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>狐火</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/NFTkokonn"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>バニリゾ</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/banilizo_nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 五段目ここまで */}

            {/* 六段目 */}
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>風和りん</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/rin_nft_"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>tmt</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/tmtjuice_nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}></RoleText>
              <MemberName className={"scroll"}>行間</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/gyoukan000nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={0} sm={3}></Grid>
            {/* 六段目ここまで */}

            {/* 協力 */}

            <Grid xs={12} sm={6}>
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/specialthanks.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={0} sm={6}></Grid>

            <Grid xs={0} sm={3}></Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/CHAN.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Discord構築監修</RoleText>
              <MemberName className={"scroll"}>ガリバー</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/gulliver_nft"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/yukinko.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>楽曲提供</RoleText>
              <MemberName className={"scroll"}>r0macan</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/r0macan"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>
            <Grid xs={4} sm={2}>
              {/* <StyledMember
                alt={"morogami"}
                src={"/config/images/member/Fuggi67.png"}
                className={"scroll"}
              /> */}
              <RoleText className={"scroll"}>Ex.Co-Founder</RoleText>
              <MemberName className={"scroll"}>kuraja</MemberName>
              <LinksDiv className={"scroll"}>
                <StyledLink
                  target={"_blank"}
                  href={"https://twitter.com/kurajaNFT"}
                >
                  <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                </StyledLink>
              </LinksDiv>
            </Grid>

            <Grid xs={0} sm={3}></Grid>
            {/* 協力ここまで */}

            <Grid xs={12} sm={12}>
              <SpacerXtraLarge />
              <RoleText className={"scroll"}>and</RoleText>
              <MemberName className={"scroll"}>あなた</MemberName>
              <MemberNameDesc className={"scroll"}>YOU</MemberNameDesc>
            </Grid>
            <Grid xs={12} sm={2}></Grid>
            {/* <Grid xs={12} sm={8}>
              <SpacerXtraLarge />
              <StyledCapitulo
                alt={"morogami"}
                src={"/config/images/9.png"}
                className={"scroll"}
              />
            </Grid>
            <Grid xs={12} sm={2}></Grid> */}

            <Grid xs={12} sm={12}>
              {/* <MemberName
                className={"scroll"}
                style={{
                  fontSize: "3.5rem",
                }}
              >
                近日公開
              </MemberName>
              <MemberNameDesc className={"scroll"}>Coming soon</MemberNameDesc> */}
              <s.SpacerLarge />
              <LinksDiv className={"scroll"}>
                <MiniImage src="/config/images/10torii.png" alt="torii" />
              </LinksDiv>
            </Grid>
          </Grid>
        </Grid>
      </FrontBox>
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      <SpacerXtraLarge />
      {isMobile && (
        <>
          <SpacerXtraLarge />
          <SpacerXtraLarge />
          <SpacerXtraLarge />
          <SpacerXtraLarge />
          <SpacerXtraLarge />
          <SpacerXtraLarge />
        </>
      )}
      <s.SpacerLarge />
    </BoxList>
  );
}

export default About;
