import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Header from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import useMedia from "use-media";
import Mint from "./Mint";

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;
export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :hover {
    cursor: pointer;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledButtonReload = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 70%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font: bold 1em "Fira Sans", serif;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Home() {
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <s.Screen>
      <Header config={CONFIG} />

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <ul className="bgtrans">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        {/* {CONFIG.MINTPHASE ? <Mint id="mint-box" /> : <></>} */}
      </s.Container>

      <About id="about" />
      <Footer config={CONFIG} />
    </s.Screen>
  );
}

export default Home;
