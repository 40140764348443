import React, { useEffect, useState, useRef } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import * as s from "./../styles/globalStyles";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Box from "@mui/material/Box";

export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: none;
`;

export const Invisible = styled.button`
  display: none;
`;
export const BoxList = styled.div`
  /* background-color: #000; */
  position: relative;
  bottom: 0;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* z-index: 20; */
`;

export const StyledButton = styled.button`
  margin: 5px;
  border-radius: 10%;
  font-family: DelaGothicOne;
  position: fixed;
  right: 8px;
  bottom: 8px;
  border: none;
  background-color: black;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  /* width: 10%; */
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-animation: mintanime 6s linear 0s infinite;
  animation: mintanime 6s linear 0s infinite;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const LinksBox = styled.div`
  display: inline-table;
  flex: 1;
  float: left;
  width: 100%;
  padding: 10px 0;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

export const ToTopImage = styled.img`
  width: 50px;
  margin: 0 auto;
`;
export const FootImage = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: 1000px) {
    min-height: 100px;
  }

  opacity: 0;
  z-index: 0;
`;

export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  width: 20%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;
export const MintDiv = styled.div`
  display: grid;
  flex: 1;
  width: 20%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

function Footer({ config }) {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    window.scrollY > 500 ? setIsVisible(true) : setIsVisible(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <BoxList image={"/config/images/1.png"}>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={5}></Grid>
          <Grid
            xs={12}
            sm={2}
            style={{
              textAlign: "center",
            }}
          >
            <FootImage src="/config/images/1.png" />

            <AnchorLink href="#top">
              <ToTopImage src="/config/images/2.png" />
            </AnchorLink>
          </Grid>
          <Grid xs={12} sm={5}></Grid>
          <Grid
            xs={12}
            sm={6}
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              Copyright ©︎2022 MOROGAMI, Inc. All Rights Reserved.
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            style={{
              textAlign: "center",
            }}
          ></Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Footer;
