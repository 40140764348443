import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";
import { isMobile } from "react-device-detect";

export const StyledKeyVisual = styled.img`
  position: relative;
  height: 100vh;
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
  opacity: 0;
  z-index: -20;
`;

export const StyledKeyVisualSub = styled.img`
  position: absolute;
  height: 100vh;
  width: 100vw;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
  -webkit-animation: disappear 10s forwards;
  animation: disappear 10s forwards;
  z-index: 0;
`;

export const StyledLogo = styled.img`
  width: 90%;
  transition: width 0.5s;
  transition: height 0.5s;
  -webkit-animation: appear 3s forwards;
  animation: appear 3s forwards;
`;

export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: none;
`;

export const StyledButton = styled.button`
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-family: LatoLight, rodin-light, ms gothic, ヒラギノ角ゴ pro w3,
    hiragino kaku gothic pro, osaka, sans-serif;

  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  font-weight: bold;
  color: #999;
  width: 90%;

  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const KVBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${({ isMobile }) =>
    isMobile ? "/config/images/3_mini.png" : "/config/images/3_r.png"});
  /* background-image: url("/config/images/3_r.png"); */
  background-position: center;
  background-size: cover;
`;

export const KVTxtBox = styled.div`
  position: absolute;
  top: 30%;
  left: ${({ isMobile }) => (isMobile ? "35%" : "46%")};

  margin-top: -50px;
  margin-left: -100px;
`;

export const LinksBox = styled.div`
  display: inline-table;
  flex: 1;
  float: right;
  width: 100%;
  padding: 20px 0;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

export const LogoImage = styled.img`
  width: 30px;
`;

export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  width: 60px;
  float: right;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

export const TextDescription = styled.p`
  position: relative;
  color: white;
  text-align: left;
  font-size: 0.9rem;
  -webkit-user-select: text;
  user-select: text;
  text-align: center;
  @media (min-width: 500px) {
    font-size: 1.2rem;
  }
  line-height: 2.6;
  z-index: 0;
  -webkit-animation: appear 6s forwards;
  animation: appear 6s forwards;
`;

function Header(props) {
  const GoToMintPosition = (elementId) => {
    const mintBoxElement = document.getElementById(elementId);

    if (mintBoxElement) {
      const rect = mintBoxElement.getBoundingClientRect();
      const distanceFromTop = rect.top + window.scrollY;
      window.scrollTo({
        top: distanceFromTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: screen.height / 2,
        behavior: "smooth",
      });
    }
  };
  return (
    <AppBar
      position="static"
      style={{
        textAlign: "center",
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      }}
      id="top"
    >
      <Toolbar
        style={{
          textAlign: "center",
          backgroundColor: "rgba(0,0,0,0.9)",
          position: "fixed",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={0} sm={3}></Grid>
            <Grid xs={1} sm={1}>
              <StyledLink href={"/"}>
                <StyledButton>TOP</StyledButton>
              </StyledLink>
            </Grid>
            {/* <Grid xs={6} sm={1}>
                <AnchorLink href="/">
                  <StyledButton onClick={() => GoToMintPosition("member-list")}>
                    MEMBER
                  </StyledButton>
                </AnchorLink>
              </Grid> */}
            <Grid xs={2} sm={1}>
              <StyledLink href={"/info"}>
                <StyledButton>INFO</StyledButton>
              </StyledLink>
            </Grid>
            {/* {props.config?.MINTPHASE == true && (
              <Grid xs={2} sm={1}>
                <StyledLink href={"/mint"}>
                  <StyledButton>MINT</StyledButton>
                </StyledLink>
              </Grid>
            )} */}
            <Grid xs={2} sm={1}>
              <StyledLink href={"/stake"}>
                <StyledButton>STAKE</StyledButton>
              </StyledLink>
            </Grid>
            <Grid xs={1} sm={1}>
              <StyledLink href={"/introduction"}>
                <StyledButton>INTRO</StyledButton>
              </StyledLink>
            </Grid>

            {/* <Grid xs={6} sm={1}>
                  <AnchorLink href="#faq">
                    <StyledButton>Q&A</StyledButton>
                  </AnchorLink>
                </Grid> */}
            {/* <Grid xs={6} sm={1}>
                  <StyledLink href={"/stake"}>
                    <StyledButton>STAKING</StyledButton>
                  </StyledLink>
                </Grid> */}
            <Grid xs={0} sm={1}></Grid>
            <Grid xs={6} sm={2}>
              <LinksBox>
                <LinksDiv>
                  <StyledLink
                    target={"_blank"}
                    href={"https://twitter.com/Morogami_RD"}
                  >
                    <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                  </StyledLink>
                </LinksDiv>
                <LinksDiv>
                  <StyledLink
                    target={"_blank"}
                    href={"https://discord.gg/p4JXy6VbTE"}
                  >
                    <LogoImage src="/config/images/discord.svg" alt="discord" />
                  </StyledLink>
                </LinksDiv>
              </LinksBox>
            </Grid>
            {/* ) : (
              <>
                <Grid xs={10} sm={10}></Grid>
                <Grid xs={2} sm={2}>
                  <LinksBox>
                    <LinksDiv>
                      <StyledLink
                        target={"_blank"}
                        href={"https://twitter.com/Morogami_RD"}
                      >
                        <LogoImage
                          src="/config/images/twitter.svg"
                          alt="twitter"
                        />
                      </StyledLink>
                    </LinksDiv>
                  </LinksBox>
                </Grid>
              </>
            )} */}
          </Grid>
        </Grid>
      </Toolbar>
      {props.headerFlag == false ? (
        <></>
      ) : (
        <>
          <Grid container direction="column">
            <Grid item container>
              <Grid
                xs={12}
                sm={12}
                style={{
                  textAlign: "center",
                }}
              >
                <KVBox isMobile={isMobile}>
                  <StyledKeyVisualSub
                    alt={"logo"}
                    src={"/config/images/4.png"}
                  />
                  <StyledKeyVisual
                    alt={"logo"}
                    src={"/config/images/3_r.png"}
                  />
                  <KVTxtBox isMobile={isMobile}>
                    <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
                    <TextDescription>- M O R O G A M I -</TextDescription>
                  </KVTxtBox>
                </KVBox>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </AppBar>
  );
}

export default Header;
