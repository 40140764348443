import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataStake } from "./redux/datastake/datastakeActions";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";
import AudioPlayer from "./components/AudioPlayer";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
// import { AudioPlayer } from "./AudioPlayer";
import Header from "./components/Header";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Member from "./components/Member";
import Faq from "./components/Faq";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import CircleTextLogoLeft from "./components/CircleTextLogoLeft";
import CircleTextLogoRight from "./components/CircleTextLogoRight";
import useMedia from "use-media";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import AlCheckerTable from "./components/AlCheckerTable";
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const LinksBox = styled.div`
  display: inline-table;
  flex: 1;
  float: left;
  width: min-content;
  padding: 2px 0;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

export const LogoImage = styled.img`
  width: 30px;
`;

export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  width: 60px;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;
export const TextDescriptionTitle = styled.p`
  color: white;
  text-align: left;
  font-size: 0.8rem;
  font: bold 1em "Fira Sans", serif;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 500px) {
    font-size: 1.75rem;
  }
  line-height: 2.6;
  opacity: 0;
`;
export const TextDescription = styled.p`
  color: white;
  text-align: left;
  font-size: 0.8rem;
  font: bold 1em "Fira Sans", serif;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  line-height: 2.6;
  opacity: 0;
`;
export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const SpacerXtraLarge = styled.div`
  width: 96px;
  height: 96px;
  @media (min-width: 600px) {
    width: 192px;
    height: 192px;
  }
`;

export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.6); */
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font: bold 1em "Fira Sans", serif;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Info() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const isNotSmp = useMedia({ minWidth: "500px" });
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NSK! check on your Opensea.",
    minting: "Minting NSK....",
    salestartwl: "Presale 1st Stage：11/26(Sat) 20:00〜(JST)",
    salestartwl2: "Presale 2nd Stage：11/26(Sat) 21:00〜(JST)",
    salestartpb: "Public Sale：11/26(Sat) 22:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NSK,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    document.title = "Info";
  }, []);

  const callback = (entries) => {
    //各 entry（IntersectionObserverEntry オブジェクト）に対して
    entries.forEach((entry) => {
      //監視対象の要素が領域内に入った場合の処理
      if (entry.isIntersecting) {
        entry.target.classList.add("act");
      } else {
        // entry.target.classList.remove("act");
      }
    });
  };

  const options = {
    threshold: [0.2, 1],
  };
  const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
  const targets = document.querySelectorAll(".scroll"); //監視対象の要素（target クラスを指定した要素）を全て取得
  targets.forEach((elem) => {
    observer.observe(elem); //observe() に監視対象の要素を指定
  });
  return (
    <s.Screen>
      <Header config={CONFIG} headerFlag={false} />
      <>
        <s.Container
          flex={1}
          ai={"center"}
          style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
          image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        >
          <ul class="bgtrans">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {true ? (
            <>
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerLarge />
              <MintTitle
                id="mint"
                style={{
                  textAlign: "center",
                }}
              >
                {"Info"}
              </MintTitle>
              {/* <s.TextDescription
                style={{
                  width: "80%",
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                <AudioPlayer
                  audioFiles={[
                    "https://data.morogami.tk/Morogami.mp3",
                    // Add more audio file URLs as needed
                  ]}
                />
                <AudioPlayer></AudioPlayer>
              </s.TextDescription> */}
              <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
                <s.Container
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    padding: "0 24",
                    borderRadius: 24,
                    border: "4px var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                >
                  <s.SpacerMedium />
                  <Grid container direction="column">
                    <Grid item container>
                      <Grid xs={1} sm={3}></Grid>
                      <Grid xs={10} sm={6}>
                        <TextDescriptionTitle className={"scroll"}>
                          <img
                            src="/config/images/info/ri.png"
                            style={{ width: "5rem", maxWidth: "95%" }}
                          />
                          <br />
                          NAGOYA NFT GALLERY
                          <br />
                          R/I＝H...s（アールアイ）
                          <LinksBox>
                            <LinksDiv>
                              <StyledLink
                                target={"_blank"}
                                href={"https://twitter.com/nagoyanft"}
                              >
                                <LogoImage
                                  src="/config/images/twitter.svg"
                                  alt="twitter"
                                />
                              </StyledLink>
                            </LinksDiv>
                          </LinksBox>
                        </TextDescriptionTitle>
                      </Grid>
                      <Grid xs={1} sm={3}></Grid>

                      <Grid xs={12} sm={4}>
                        <img
                          src="/config/images/info/1.png"
                          style={{
                            width: "40rem",
                            paddingLeft: "10px",
                            maxWidth: "95%",
                            maxHeight: "95%",
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <img
                          src="/config/images/info/2.png"
                          style={{
                            width: "40rem",
                            paddingLeft: "10px",
                            maxWidth: "95%",
                            maxHeight: "95%",
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <img
                          src="/config/images/info/3.png"
                          style={{
                            width: "40rem",
                            paddingLeft: "10px",
                            maxWidth: "95%",
                            maxHeight: "95%",
                          }}
                        />
                      </Grid>

                      <Grid xs={1} sm={3}></Grid>
                      <Grid xs={10} sm={6}>
                        <TextDescription className={"scroll"}>
                          店舗内のデジタルサイネージに諸神のアートが展示されます。
                          <br />
                          【期間】 9月21日(木) (22日～24日は休業日) <br />
                          9月25日(月) ～ 9月30(土)まで
                          <br />
                          【場所】 〒450-0002 <br />
                          愛知県名古屋市中村区名駅２丁目４５−７ <br />
                          店員さんに「諸神見に来ました」とお伝えいただければ、秘密の言葉を教えます🤫{" "}
                          <br />
                          その特別な言葉を使って、『百影(ももかげ)のNFT』をエアドロップで入手できます！{" "}
                          <br />
                          詳しい方法は、Discordでのアナウンスをお待ちください。{" "}
                          <br />
                          ◆店舗サイト
                          <br />
                          <LinksBox>
                            <LinksDiv>
                              <StyledLink
                                target={"_blank"}
                                href={"https://nagoyanft.art/"}
                                style={{
                                  textDecoration: "underline",
                                  color: "red",
                                }}
                              >
                                {"https://nagoyanft.art/"}
                              </StyledLink>
                            </LinksDiv>
                          </LinksBox>
                        </TextDescription>
                        <SpacerXtraLarge />
                      </Grid>
                      <Grid xs={1} sm={3}></Grid>
                    </Grid>
                  </Grid>
                </s.Container>
                <s.SpacerLarge />
              </ResponsiveWrapper>
            </>
          ) : (
            <></>
          )}
        </s.Container>

        <Footer config={CONFIG} />
      </>
    </s.Screen>
  );
}

export default Info;
