import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataPhase } from "./redux/dataphase/dataphaseActions";
import { AudioPlayer } from "./AudioPlayer";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const LogoImage = styled.img`
  width: 35px;
  :hover {
    -webkit-filter: contrast(10%);
    -moz-filter: contrast(10%);
    -ms-filter: contrast(10%);
    -o-filter: contrast(10%);
    filter: contrast(10%);
    transition: filter 0.7s;
  }
`;
export const MiniImage = styled.img`
  width: 150px;
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const RoleText = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  font-size: 0.8rem;
  -webkit-user-select: text;
  user-select: text;
  color: red;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  /* line-height: 2.6; */
  opacity: 0;
`;
export const MemberName = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  font-weight: 900;
  text-align: center;
  font-size: 0.9rem;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  /* line-height: 2.9; */
  opacity: 0;
`;
export const StyledCapitulo = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 100%;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
  opacity: 0;
`;
export const StyledMember = styled.img`
  width: 80%;
  margin-left: 10%;
  /* @media (min-width: 767px) {
    width: 100%;
  } */
`;

export const AboutTitle = styled.p`
  color: var(--accent-text);
  /* font-family: DelaGothicOne; */
  font: bold 1em "Fira Sans", serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const SpacerMediumLarge = styled.div`
  width: 36px;
  height: 36px;
  @media (min-width: 600px) {
    width: 72px;
    height: 72px;
  }
`;
export const MemberNameDesc = styled.p`
  color: white;
  font: bold 1em "Fira Sans", serif;
  text-align: center;
  font-size: 0.6rem;
  -webkit-user-select: none;
  user-select: none;
  @media (min-width: 767px) {
    font-size: 0.8rem;
  }
  line-height: 2.6;
  opacity: 0;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;
export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  padding-bottom: 1rem;
  width: 100%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  text-align: center;
`;
export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  background-size: 700% 700%;
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: 0.2rem;
    background-position:center;
    animation: none;
    min-width: 200px;
  background-size: 100%;
`}
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;
export const FrontBox = styled.div`
  position: absolute;
  width: 100%;
  z-index: 20;
`;
export const TextDescription = styled.p`
  color: white;
  text-align: left;
  font-size: 0.8rem;
  font: bold 1em "Fira Sans", serif;
  -webkit-user-select: text;
  user-select: text;
  @media (min-width: 767px) {
    font-size: 1.2rem;
  }
  line-height: 2.6;
  /* opacity: 0; */
`;
export const SpacerXtraLarge = styled.div`
  width: 96px;
  height: 96px;
  @media (min-width: 600px) {
    width: 192px;
    height: 192px;
  }
`;

export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;
export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :hover {
    cursor: pointer;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledButtonReload = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 70%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font: bold 1em "Fira Sans", serif;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  font-weight: 700;
  padding: 0;
  border-radius: 100%;
  margin: 0 4rem;
  /* border: solid 1px #333; */
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  font-weight: bold;
  font-size: 2.5rem;
  color: #fff;
  width: min-content;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3); */
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font: bold 1em "Fira Sans", serif;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const NoButton = ({ text }) => {
  return (
    <>
      <s.Container ai={"center"} jc={"center"} fd={"row"}>
        <StyledIncDecButton style={{ lineHeight: 0.4 }} disabled={true}>
          -
        </StyledIncDecButton>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
            padding: 15,
            fontSize: "1.3rem",
          }}
        >
          {"-"}
        </s.TextDescription>
        <StyledIncDecButton disabled={true}>+</StyledIncDecButton>
      </s.Container>
      <s.Container ai={"center"} jc={"center"} fd={"row"}>
        <StyledButton disabled={true}>{text}</StyledButton>
      </s.Container>
    </>
  );
};

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const dataphase = useSelector((state) => state.dataphase);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [lang, setlang] = useState(false);

  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountAL0, setMintAmountAL0] = useState(1);
  const [mintAmountAL1, setMintAmountAL1] = useState(1);
  const [mintAmountAL2, setMintAmountAL2] = useState(1);

  const [allowlistProof0, setAllowlistProof0] = useState("");
  const [allowlistValid0, setAllowlistValid0] = useState(false);
  const [allowlistProof1, setAllowlistProof1] = useState("");
  const [allowlistValid1, setAllowlistValid1] = useState(false);
  const [allowlistProof2, setAllowlistProof2] = useState("");
  const [allowlistValid2, setAllowlistValid2] = useState(false);

  const [minted, setminted] = useState(0);
  const [mintedAL0, setMintedAL0] = useState(0);
  const [mintedAL1, setMintedAL1] = useState(0);
  const [mintedAL2, setMintedAL2] = useState(0);

  const [allowlistRoot0, setAllowlistRoot0] = useState("");
  const [allowlistRoot1, setAllowlistRoot1] = useState("");
  const [allowlistRoot2, setAllowlistRoot2] = useState("");

  const [canMint, setcanMint] = useState(99);
  const [canMintAl0, setcanMintAl0] = useState(1);
  const [canMintAl1, setcanMintAl1] = useState(1);
  const [canMintAl2, setcanMintAl2] = useState(1);

  const [revealNum, setrevealNum] = useState("");

  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NFT! check on your Opensea.",
    minting: "Minting NFT....",
    salestartTier1_2: "Tier1 & Tier2 9/23(Sat) 20:00〜(JST)",
    salestartTier3Free: "Tier3 & Free 9/24(Sun) 20:00〜(JST)",
    salestartpb: "Public 9/25(Sat) 20:00〜(JST)",
    soldout: "Sold Out! thank you!",
    started: "started!",
    notopen: "not open",
    wl: "Tier1&2",
    wl2: "Tier3",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NFT, Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "Minted!",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
    waitforsale: "Wait For Sale...",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const creditIDAL0 = "e3a1f20c-b3d4-4640-9d54-c3cc06dd32e5";
  const creditIDAL1 = "c616c0db-a2a3-4ef9-a83c-bf68d646cb42";
  const creditIDAL2 = "9d0c1ecc-974c-4cab-a1df-087f9cff13a6";
  const creditIDPb = "104cf7c0-d8b0-4226-aa3f-d78e0e9d5f95";
  // const handleClick = (url, filename) => {
  //   alert("読みこむので少々お待ちください。");
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // };
  const claimNFTsAl = (_phaseId) => {
    let cost = CONFIG.WEI_COSTAL0;
    let amount = 0;
    let allowlistProof = "";
    switch (_phaseId) {
      case 0:
        allowlistProof = allowlistProof0;
        amount = mintAmountAL0;
        cost = CONFIG.WEI_COSTAL0;
        break;
      case 1:
        allowlistProof = allowlistProof1;
        amount = mintAmountAL1;
        cost = CONFIG.WEI_COSTAL1;
        break;
      case 2:
        allowlistProof = allowlistProof2;
        amount = mintAmountAL2;
        cost = CONFIG.WEI_COSTAL2;
        break;
      default:
        console.log("not found PhaseID");
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting....`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .allowlistMint(_phaseId, amount, blockchain.account, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedAL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount, blockchain.account)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        // checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(revealNum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataPhase(blockchain.account));
      });
  };
  const alRegist = (_phaseId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let alRoot;

    switch (_phaseId) {
      case 0:
        alRoot = allowlistRoot0;
        break;
      case 1:
        alRoot = allowlistRoot1;
        break;
      case 2:
        alRoot = allowlistRoot2;
        break;
      case 3:
        alRoot = allowlistRoot3;
        break;
      case 4:
        alRoot = allowlistRoot4;
        break;
      case 5:
        alRoot = allowlistRoot5;
        break;
      case 6:
        alRoot = allowlistRoot6;
        break;
      case 7:
        alRoot = allowlistRoot7;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`SETMERKLE AL${_phaseId}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootAlWithId(_phaseId, alRoot)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にAL" + _phaseId + "登録しました！");
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にAL" + _phaseId + "セール開始されました！")
            : setFeedback("正常にAL" + _phaseId + "セール停止されました！");
        }
        setClaimingNft(false);
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI("https://startdata.io/NSK/")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
      });
  };
  const setWlMaxMints = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let maxMintNum = wlMaxMintNum;

    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`上限設定中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWlMaxMints(maxMintNum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `WL.MAX:${maxMintNum}::上限が設定されました。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
  };

  const checkMinted = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > canMint - minted) {
      newMintAmount = canMint - minted;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const incrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 + diffNum;
    let maxNum = canMintAl0 - mintedAL0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const decrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const incrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 + diffNum;
    let maxNum = canMintAl1 - mintedAL1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const decrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const incrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 + diffNum;
    let maxNum = canMintAl2 - mintedAL2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getDataBase = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase(blockchain.account));
    }
  };

  const getAllowlist = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/al" + _phaseId + ".json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, _phaseId, proof)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setAllowlistProof0(proof);
              setAllowlistValid0(receipt);
              console.log("al0:valid:" + [receipt]);
              // if (canMintAlo > 0) {
              //   setMintable0(canMintAl0 - mintedAL0);
              // }
              console.log("AL[" + _phaseId + "]:proof/" + allowlistProof0);
              break;
            case 1:
              console.log("al1:valid:" + [receipt]);
              setAllowlistProof1(proof);
              setAllowlistValid1(receipt);
              // if (canMintAl1 > 0) {
              //   setMintable1(canMintAl1 - mintedAL1);
              // }
              console.log("AL[" + _phaseId + "]:proof/" + allowlistProof1);
              break;
            case 2:
              setAllowlistProof2(proof);
              setAllowlistValid2(receipt);
              // if (canMintAl2 > 0) {
              //   setMintable2(canMintAl2 - mintedAL2);
              // }
              console.log("AL[" + _phaseId + "]:proof/" + allowlistProof2);
              break;

              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          setAllowlistRoot0(root);
          console.log("AL0" + _phaseId + ":proof/" + allowlistProof0);
          break;
        case 1:
          setAllowlistRoot1(root);
          console.log("AL1" + _phaseId + ":proof/" + allowlistProof1);
          break;
        case 2:
          setAllowlistRoot2(root);
          console.log("AL2" + _phaseId + ":proof/" + allowlistProof2);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("AL" + _phaseId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };
  const getMaxMint = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .getMaxMint(_phaseId, blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setcanMintAl0(receipt);
              console.log("al0:canMin:" + [receipt]);
              break;
            case 1:
              console.log("al1:canMin:" + [receipt]);
              setcanMintAl1(receipt);
              break;
            case 2:
              console.log("al2:canMin:" + [receipt]);
              setcanMintAl2(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          setAllowlistRoot0(root);
          console.log("AL0" + _phaseId + ":proof/" + allowlistProof0);
          break;
        case 1:
          setAllowlistRoot1(root);
          console.log("AL1" + _phaseId + ":proof/" + allowlistProof1);
          break;
        case 2:
          setAllowlistRoot2(root);
          console.log("AL2" + _phaseId + ":proof/" + allowlistProof2);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("AL" + _phaseId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };

  const checkMintedAL = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alIdMinted(_phaseId, blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setMintedAL0(receipt);
              break;
            case 1:
              setMintedAL1(receipt);
              break;
            case 2:
              setMintedAL2(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
    }
    getData();
    getMaxMint(0);
    getMaxMint(1);
    getMaxMint(2);
    getAllowlist(0);
    getAllowlist(1);
    getAllowlist(2);
    checkMintedAL(0);
    checkMintedAL(1);
    checkMintedAL(2);
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  useEffect(() => {
    getConfig();
    getInit();
  }, []);

  useEffect(() => {
    getData();
    getDataBase();
    getDataSub();
    getInit();
  }, [blockchain.account]);

  return (
    <>
      <s.TextDescription
        style={{ textAlign: "center", color: "var(--accent-text)" }}
      >
        <AudioPlayer></AudioPlayer>
      </s.TextDescription>
      <BoxList id="mint-boxes">
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={12} sm={4}>
              <s.Container flex={2} jc={"left"} ai={"left"}>
                <s.TextTitle
                  style={{
                    textAlign: "left",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  LANG/言語
                </s.TextTitle>
                <s.SpacerSmall />
                <div className="toggle-switch">
                  <input
                    id="toggleBox"
                    className="toggle-input"
                    type="checkbox"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      // e.preventDefault();
                      changeLang();
                    }}
                  />
                  <label htmlFor="toggleBox" className="toggle-label" />
                </div>
              </s.Container>
            </Grid>
            <Grid xs={12} sm={4}>
              <TextDescription className={""}>
                {/* セール状況ここから */}
                {/* セール状況ここから */}
                {/* セール状況ここから */}
                {/* セール状況ここから */}
                {(allowlistValid0 && database.isAlEnabled0) ||
                (allowlistValid1 && database.isAlEnabled1) ||
                data.isPublicSaleEnabled ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "var(--accent-text)",
                      }}
                    >
                      {allowlistValid0 && database.isAlEnabled0 ? "<Free>" : ""}
                      {allowlistValid1 && database.isAlEnabled1
                        ? "　<Tier1>　<Tier2>"
                        : ""}
                      {allowlistValid2 && database.isAlEnabled2
                        ? "　<Tier3>"
                        : ""}
                      {data.isPublicSaleEnabled ? "　<Public>" : ""}
                      <s.SpacerSmall />

                      {"is Now On Sale!"}
                    </s.TextTitle>
                    {/* <s.SpacerLarge />
                              <s.SpacerLarge /> */}
                    <s.SpacerLarge />
                  </>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "var(--accent-text)",
                      }}
                    >
                      {langData.waitforsale}
                    </s.TextTitle>
                  </>
                )}
                {/* セール状況ここまで */}
                {/* セール状況ここまで */}
                {/* セール状況ここまで */}
                {/* セール状況ここまで */}{" "}
              </TextDescription>

              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                      font: 'bold 1em "Fira Sans",serif',
                    }}
                  >
                    {langData.network}
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect(lang));
                      getData();
                    }}
                  >
                    CONNECT
                  </StyledButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {langData.soldout}
                      </s.TextTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        You can still find {CONFIG.NFT_NAME} on
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK}
                      >
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "gray" }}
                      >
                        {langData.salestartTier1_2}
                      </s.TextTitle>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "gray" }}
                      >
                        {langData.salestartTier3Free}
                      </s.TextTitle>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "gray" }}
                      >
                        {langData.salestartpb}
                      </s.TextTitle>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <></>
              ) : (
                <>
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <></>
                  ) : (
                    <>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "var(--accent-text)",
                        }}
                      >
                        {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                      </s.TextTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--primary-text)",
                        }}
                      >
                        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                          {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                        </StyledLink>
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        1 {CONFIG.SYMBOL} costs
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {langData.wl}
                        {" / "}
                        {CONFIG.DISPLAY_COSTAL1}
                        {CONFIG.NETWORK.SYMBOL}
                        {/* {`(Max ${datasub.maxMintsPerWL}mint)`} */}
                      </s.TextTitle>{" "}
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {langData.wl2}
                        {" / "}
                        {CONFIG.DISPLAY_COSTAL2}
                        {CONFIG.NETWORK.SYMBOL}
                        {/* {`(Max ${datasub.maxMintsPerWL}mint)`} */}
                      </s.TextTitle>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {langData.pb}
                        {" / "}
                        {CONFIG.DISPLAY_COST}
                        {CONFIG.NETWORK.SYMBOL}
                        {/* {`(Max ${datasub.maxMintsPerPS}mint)`} */}
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {langData.gasfee}
                      </s.TextDescription>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <></>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <></>
                  ) : (
                    <>
                      <s.Container>
                        <s.Container>
                          {/* Free所有者の方ここから */}
                          {/* Free所有者の方ここから */}
                          {/* Free所有者の方ここから */}
                          {/* Free所有者の方ここから */}
                          <s.Container ai={"left"} jc={"left"} fd={"row"}>
                            <MintTitle>
                              {"Free Mint"}

                              {allowlistValid0 && (
                                <>{` [${mintedAL0}/${canMintAl0}] `}</>
                              )}
                              {database.isAlEnabled0
                                ? langData.started
                                : langData.notopen}
                            </MintTitle>
                          </s.Container>
                          <s.SpacerMedium />
                          {allowlistValid0 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  {canMintAl0 <= mintedAL0 ? (
                                    <NoButton text={langData.minted} />
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          -
                                        </StyledIncDecButton>
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 15,
                                            fontSize: "1.3rem",
                                          }}
                                        >
                                          {mintAmountAL0}
                                        </s.TextDescription>
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          +
                                        </StyledIncDecButton>
                                      </s.Container>

                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(0);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : `Mint<${
                                                Math.round(
                                                  CONFIG.DISPLAY_COSTAL0 *
                                                    mintAmountAL0 *
                                                    1000
                                                ) / 1000
                                              } ETH>`}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerSmall />
                                      {allowlistValid0 && (
                                        <>
                                          {database.isAlEnabled0 && (
                                            <s.Container
                                              ai={"center"}
                                              jc={"center"}
                                              fd={"row"}
                                            >
                                              <StyledLink
                                                target={"_blank"}
                                                href={`https://paypiement.xyz/projects/${creditIDAL0}?quantity=${mintAmountAL0}&recipientAddress=${blockchain.account}`}
                                              >
                                                <StyledButton
                                                  bgImage={
                                                    !claimingNft &&
                                                    "/config/images/credit.png"
                                                  }
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    // e.preventDefault();
                                                  }}
                                                >
                                                  {claimingNft
                                                    ? "MINTING.."
                                                    : `<Mint ${
                                                        Math.round(
                                                          CONFIG.DISPLAY_COSTAL1 *
                                                            mintAmountAL1 *
                                                            1000
                                                        ) / 1000
                                                      } ETH>`}
                                                </StyledButton>
                                              </StyledLink>
                                            </s.Container>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <NoButton text={langData.notopen} />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <NoButton text={"Not have."} />
                            </>
                          )}
                          {/* Free所有者の方ここまで */}
                          {/* Free所有者の方ここまで */}
                          {/* Free所有者の方ここまで */}
                          {/* Free所有者の方ここまで */}
                        </s.Container>
                      </s.Container>
                      <s.SpacerLarge />
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid xs={12} sm={4}>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <></>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <></>
                  ) : (
                    <>
                      <s.Container>
                        {/* tier1_2所有者の方ここから */}
                        {/* tier1_2所有者の方ここから */}
                        {/* tier1_2所有者の方ここから */}
                        {/* tier1_2所有者の方ここから */}
                        <s.Container ai={"left"} jc={"left"} fd={"row"}>
                          <MintTitle>
                            Tier1/Tier2 Mint
                            {allowlistValid1 && (
                              <>{` [${mintedAL1}/${canMintAl1}] `}</>
                            )}
                            {database.isAlEnabled1
                              ? langData.started
                              : langData.notopen}
                          </MintTitle>
                        </s.Container>
                        <s.SpacerMedium />
                        {allowlistValid1 ? (
                          <>
                            {database.isAlEnabled1 ? (
                              <>
                                {canMintAl1 <= mintedAL1 ? (
                                  <NoButton text={langData.minted} />
                                ) : (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementMintAmountAL1(1);
                                          getData();
                                        }}
                                      >
                                        -
                                      </StyledIncDecButton>
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 15,
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        {mintAmountAL1}
                                      </s.TextDescription>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementMintAmountAL1(1);
                                          getData();
                                        }}
                                      >
                                        +
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsAl(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "MINTING.."
                                          : `Mint<${
                                              Math.round(
                                                CONFIG.DISPLAY_COSTAL1 *
                                                  mintAmountAL1 *
                                                  1000
                                              ) / 1000
                                            } ETH>`}
                                      </StyledButton>
                                    </s.Container>
                                    <s.SpacerSmall />
                                    {allowlistValid1 && (
                                      <>
                                        {database.isAlEnabled1 && (
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              href={`https://paypiement.xyz/projects/${creditIDAL1}?quantity=${mintAmountAL1}&recipientAddress=${blockchain.account}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : `tier1_2 ${
                                                      Math.round(
                                                        CONFIG.DISPLAY_COSTAL1 *
                                                          mintAmountAL1 *
                                                          1000
                                                      ) / 1000
                                                    }`}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <NoButton text={langData.notopen} />
                            )}
                          </>
                        ) : (
                          <NoButton text={"Not have."} />
                        )}
                        {/* tier1_2所有者の方ここまで */}
                        {/* tier1_2所有者の方ここまで */}
                        {/* tier1_2所有者の方ここまで */}
                        {/* tier1_2所有者の方ここまで */}
                      </s.Container>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid xs={12} sm={4}>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <></>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <></>
                  ) : (
                    <>
                      <s.Container>
                        {/* tier3所有者の方ここから */}
                        {/* tier3所有者の方ここから */}
                        {/* tier3所有者の方ここから */}
                        {/* tier3所有者の方ここから */}
                        <s.Container ai={"left"} jc={"left"} fd={"row"}>
                          <MintTitle>
                            Tier3 Mint
                            {allowlistValid2 && (
                              <>{` [${mintedAL2}/${canMintAl2}] `}</>
                            )}
                            {database.isAlEnabled2
                              ? langData.started
                              : langData.notopen}
                          </MintTitle>
                        </s.Container>
                        <s.SpacerMedium />
                        {allowlistValid2 ? (
                          <>
                            {database.isAlEnabled2 ? (
                              <>
                                {canMintAl2 <= mintedAL2 ? (
                                  <>
                                    <NoButton text={langData.minted} />
                                  </>
                                ) : (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementMintAmountAL2(1);
                                          getData();
                                        }}
                                      >
                                        -
                                      </StyledIncDecButton>
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 15,
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        {mintAmountAL2}
                                      </s.TextDescription>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementMintAmountAL2(1);
                                          getData();
                                        }}
                                      >
                                        +
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsAl(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "MINTING.."
                                          : `Mint<${
                                              Math.round(
                                                CONFIG.DISPLAY_COSTAL2 *
                                                  mintAmountAL2 *
                                                  1000
                                              ) / 1000
                                            } ETH>`}
                                      </StyledButton>
                                    </s.Container>
                                    <s.SpacerSmall />
                                    {allowlistValid2 && (
                                      <>
                                        {database.isAlEnabled2 && (
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              href={`https://paypiement.xyz/projects/${creditIDAL2}?quantity=${mintAmountAL2}&recipientAddress=${blockchain.account}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : `tier1_2 ${
                                                      Math.round(
                                                        CONFIG.DISPLAY_COSTAL2 *
                                                          mintAmountAL2 *
                                                          1000
                                                      ) / 1000
                                                    }`}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <NoButton text={langData.notopen} />
                            )}
                          </>
                        ) : (
                          <NoButton text={"Not have."} />
                        )}
                        {/* tier3所有者の方ここまで */}
                        {/* tier3所有者の方ここまで */}
                        {/* tier3所有者の方ここまで */}
                        {/* tier3所有者の方ここまで */}
                      </s.Container>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} sm={4}></Grid>

            <Grid xs={12} sm={4}>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <></>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <></>
                  ) : (
                    <>
                      <s.Container>
                        {/* ここからパブリックセール */}
                        {/* 通常パブセ */}
                        <s.Container ai={"left"} jc={"left"} fd={"row"}>
                          <MintTitle>
                            Public Mint　
                            {data.isPublicSaleEnabled
                              ? langData.started
                              : langData.notopen}
                          </MintTitle>
                        </s.Container>{" "}
                        {data.isPublicSaleEnabled ? (
                          <>
                            {CONFIG.MAX_MINT == minted ? (
                              <NoButton text={langData.minted} />
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledIncDecButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    -
                                  </StyledIncDecButton>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                      padding: 15,
                                      fontSize: "1.3rem",
                                    }}
                                  >
                                    {mintAmount}
                                  </s.TextDescription>
                                  <StyledIncDecButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    +
                                  </StyledIncDecButton>
                                </s.Container>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft
                                      ? "MINTING.."
                                      : `Mint<${
                                          Math.round(
                                            CONFIG.DISPLAY_COST *
                                              mintAmount *
                                              1000
                                          ) / 1000
                                        } ETH>`}
                                  </StyledButton>
                                </s.Container>

                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledLink
                                    target={"_blank"}
                                    href={`https://paypiement.xyz/projects/${creditIDPb}?quantity=${mintAmountAL2}&recipientAddress=${blockchain.account}`}
                                  >
                                    <StyledButton
                                      bgImage={
                                        !claimingNft &&
                                        "/config/images/credit.png"
                                      }
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        // e.preventDefault();
                                      }}
                                    >
                                      {claimingNft
                                        ? "MINTING.."
                                        : `public ${
                                            Math.round(
                                              CONFIG.DISPLAY_COSTAL2 *
                                                mintAmountAL2 *
                                                1000
                                            ) / 1000
                                          }`}
                                    </StyledButton>
                                  </StyledLink>
                                </s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <NoButton text={langData.notopen} />
                        )}
                        {/* 通常パブセここまで */}
                      </s.Container>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} sm={4}></Grid>
            <Grid xs={12} sm={6}></Grid>
            <Grid xs={12} sm={6}></Grid>
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <></>
            ) : (
              <>
                <Grid xs={12} sm={12}>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledLink
                      target={"_blank"}
                      href={CONFIG.MARKETPLACE_LINK}
                    >
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </s.Container>{" "}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </BoxList>

      <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            padding: 24,
            borderRadius: 24,
          }}
        >
          <StyledButtonReload
            onClick={(e) => {
              e.preventDefault();
              getReload();
            }}
          >
            {claimingNft ? "Busy" : langData.reload}
          </StyledButtonReload>

          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {blockchain.account === "" ||
          blockchain.smartContract === null ||
          blockchain.account === undefined ? (
            <></>
          ) : (
            <>
              {"0x637d25D0769f747B2742A04d249802dA85395970".toLowerCase() ==
              blockchain.account.toLowerCase() ? (
                <div style={{ zIndex: "99999" }}>
                  <s.Container
                    ai={"center"}
                    jc={"center"}
                    id="admin-menu"
                    style={{
                      position: "fixed",
                      top: "1rem",
                      left: "1rem",
                      width: "10rem",
                    }}
                  >
                    {!database.isAlEnabled0 ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setALSaleEnable(0, true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Free.Start"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setALSaleEnable(0, false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "Free.Stop"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    {!database.isAlEnabled1 ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setALSaleEnable(1, true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Tier1&2.Start"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setALSaleEnable(1, false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "Tier1&2.Stop"}
                        </StyledButtonOparatorOff>
                      </>
                    )}

                    {!database.isAlEnabled2 ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setALSaleEnable(2, true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Tier3.Start"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setALSaleEnable(2, false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "Tier3.Stop"}
                        </StyledButtonOparatorOff>
                      </>
                    )}

                    {!data.isPublicSaleEnabled ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setPublicSaleEnable(true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Public.Start"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleEnable(false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "Public.Stop"}
                        </StyledButtonOparatorOff>
                      </>
                    )}

                    <StyledButtonOparator
                      onClick={(e) => {
                        e.preventDefault();
                        withdraw();
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "WITHDRAW"}
                    </StyledButtonOparator>

                    {allowlistRoot0 != "" && (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          alRegist(0);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Free登録"}
                      </StyledButtonOparator>
                    )}

                    {allowlistRoot1 != "" && (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          alRegist(1);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Tier1&2登録"}
                      </StyledButtonOparator>
                    )}

                    {allowlistRoot2 != "" && (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          alRegist(2);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Tier3登録"}
                      </StyledButtonOparator>
                    )}
                  </s.Container>
                  <s.Container
                    ai={"center"}
                    jc={"center"}
                    style={{
                      position: "fixed",
                      top: "3rem",
                      right: "1rem",
                      width: "10rem",
                    }}
                  >
                    {dataphase.FreePhase != null ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          alUp(0);
                          getData();
                        }}
                      >
                        {claimingNft
                          ? "Busy"
                          : `L-P-Eリセット(${dataphase.FreePhase})`}
                      </StyledButtonOparator>
                    ) : (
                      <></>
                    )}
                    {dataphase.tier1_2Phase != null ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          alUp(1);
                          getData();
                        }}
                      >
                        {claimingNft
                          ? "Busy"
                          : `Creditリセット(${dataphase.tier1_2Phase})`}
                      </StyledButtonOparator>
                    ) : (
                      <></>
                    )}
                  </s.Container>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
          {/* オペレーションボタン */}
        </s.Container>
      </ResponsiveWrapper>
    </>
  );
}

export default Mint;
