import React, { useEffect, useState } from "react";
import * as s from "./../styles/globalStyles";

function AlCheckerTable({ wa }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [lang, setlang] = useState(false);
  const [langData, setlangData] = useState({
    result: "AL Checker",
    haveAL: "You have",
    canmint: "Can Mint",
    mai: "NFTs.",
  });

  const changeLang = () => {
    setlang(!lang);
    let langJson = {
      result: "ALチェッカー結果",
      haveAL: "所持AL",
      canmint: "ミント可能数",
      mai: "枚",
    };
    if (lang) {
      langJson = {
        result: "AL Checker",
        haveAL: "You have",
        canmint: "Can Mint",
        mai: "NFTs.",
      };
    }
    setlangData(langJson);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://mana-ka.com/alchecker_mrg/?json=true&wa=${wa}`
        );
        const result = await response.json();
        setData(result.results);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [wa]);

  return (
    <div className="alcheck">
      <s.Container flex={2} jc={"center"} ai={"center"}>
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          LANG/言語
        </s.TextTitle>
        <s.SpacerSmall />
        <div className="toggle-switch">
          <input
            id="toggleBox"
            className="toggle-input"
            type="checkbox"
            style={{ display: "none" }}
            onChange={(e) => {
              // e.preventDefault();
              changeLang();
            }}
          />
          <label htmlFor="toggleBox" className="toggle-label" />
        </div>
      </s.Container>
      <h1
        style={{
          width: "20vw",
          textAlign: "center",
          margin: "0 auto",
          padding: "1rem 0",
        }}
      >
        {langData.result}
      </h1>
      {isLoading ? (
        <p
          style={{
            width: "20vw",
            textAlign: "center",
            margin: "0 auto",
            padding: "1rem 0",
          }}
        >
          Loading...
        </p>
      ) : (
        <table style={{ width: "60vw", textAlign: "center", margin: "0 auto" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "20vw",
                  textAlign: "right",
                  padding: "1rem",
                }}
              >
                {langData.haveAL}
              </th>
              <th
                style={{
                  width: "20vw",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                {langData.canmint}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    width: "20vw",
                    textAlign: "right",
                    paddingRight: "1rem",
                  }}
                >
                  {item.sheetTitle}
                </td>
                <td
                  style={{
                    width: "20vw",
                    textAlign: "left",
                    paddingLeft: "1rem",
                  }}
                >
                  {item.mintNumber} {langData.mai}
                </td>
                {/* {item.row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))} */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AlCheckerTable;
