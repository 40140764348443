import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataStake } from "./redux/datastake/datastakeActions";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { AudioPlayer } from "./AudioPlayer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: #ffffff;
  line-height: 2;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  font: bold 1em "Fira Sans", serif;
  width: 98%;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-125deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonLoading = styled.button`
  color: #fff;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  background-size: 700% 700%;
  :hover {
    cursor: pointer;
  }
`;

export const StyledButtonTab = styled.button`
  color: #fff;
  font: bold 1em "Fira Sans", serif;
  width: 100%;
  max-width: 300px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 1rem;
  margin: 0 1rem 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  background-size: 0.2rem;
  animation: gradation 10.4s ease-in infinite; /* background-color: #fff; */
  font-size: 1rem;
  font-weight: 900;
  background-size: 700% 700%;
  :hover {
    cursor: pointer;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0;
  /* border-radius: 24px; */
  /* border: 4px solid #333; */
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font: bold 1em "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.6); */
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const datastake = useSelector((state) => state.datastake);

  const [dispFrame, setDispFrame] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [loadingLevel, setLoadingLevel] = useState([]);
  const [feedback, setFeedback] = useState(``);
  const [lang, setlang] = useState(false);
  const [canLvUp, setCanLvUp] = useState(false);
  const [pointRequire, setPointRequire] = useState(false);
  const [stakeSelect, setStakeSelect] = useState([]);
  const [statusSelect, setStatusSelect] = useState(false);
  const [stakingTime, setStakingTime] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [levels, setLevels] = useState([]);
  const [levelsSt, setLevelsSt] = useState([]);
  const [unstakeSelect, setUnStakeSelect] = useState([]);
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NSK! check on your Opensea.",
    minting: "Minting NSK....",
    salestartwl: "Presale 1st Stage：11/26(Sat) 20:00〜(JST)",
    salestartwl2: "Presale 2nd Stage：11/26(Sat) 21:00〜(JST)",
    salestartpb: "Public Sale：11/26(Sat) 22:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NSK,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
    hold: "hold",
    instaking: "In Staking",
    status: "status",
    selectall: "select all",
    stake: "stake",
    unstake: "unstake",
    selectgod: "select god to stake",
    levelup: "level UP!",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const approveAll = async () => {
    alert("諸神NFTをステーキングするには許可をしてください");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    console.log("CONFIG.STAKE_CONTRACT_ADDRESS");
    console.log(CONFIG.STAKE_CONTRACT_ADDRESS);

    let method = await blockchain.smartContract.methods.setApprovalForAll(
      CONFIG.STAKE_CONTRACT_ADDRESS,
      true
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setApprovalForAll(CONFIG.STAKE_CONTRACT_ADDRESS, true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(false);
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const staking = async () => {
    if (stakeSelect.length == 0) {
      alert("何か選択してください。");
      return;
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.stakeMulti(
      stakeSelect
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .stakeMulti(stakeSelect)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);

        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const getLevelChallenge = (_status, _point) => {
    const _level = Number(_status[0]);
    switch (_level) {
      case 1:
        if (CONFIG.expTable_1 > _point) {
          setCanLvUp(false);
          setPointRequire(CONFIG.expTable_1 - _point);
        } else {
          setCanLvUp(true);
          setPointRequire(0);
        }
        break;
      case 2:
        if (CONFIG.expTable_2 > _point) {
          setPointRequire(CONFIG.expTable_1 - _point);
          setCanLvUp(false);
        } else {
          setCanLvUp(true);
          setPointRequire(0);
        }
        break;
      case 3:
        if (CONFIG.expTable_3 > _point) {
          setPointRequire(CONFIG.expTable_1 - _point);
          setCanLvUp(false);
        } else {
          setCanLvUp(true);
          setPointRequire(0);
        }
        break;
      case 4:
        if (CONFIG.expTable_4 > _point) {
          setPointRequire(CONFIG.expTable_1 - _point);
          setCanLvUp(false);
        } else {
          setCanLvUp(true);
          setPointRequire(0);
        }
        break;
      default:
        setPointRequire(0);
        setCanLvUp(false);
        console.log("not found _level", _status, _point);
    }
  };

  const unstaking = async () => {
    if (unstakeSelect.length == 0) {
      alert("何か選択してください。");
      return;
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.unstakeMulti(
      unstakeSelect
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .unstakeMulti(unstakeSelect)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);

        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const levelUp = async (_tokenId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.levelUp(_tokenId);
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .levelUp(_tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const getLevel = (_tokenId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.stakeSmartContract !== null
    ) {
      let loadingArray = loadingLevel;
      loadingArray[_tokenId] = true;
      setLoadingLevel(loadingArray);
      blockchain.stakeSmartContract.methods
        .getLevel(_tokenId)
        .call()
        .then((receipt) => {
          let levelArray = levels;
          levelArray[_tokenId] = receipt;
          setLevels(levelArray);
          let loadingArray = loadingLevel;
          loadingArray[_tokenId] = false;
          setLoadingLevel(loadingArray);
          dispatch(fetchData(blockchain.account));
        });
      blockchain.statusSmartContract.methods
        .getLevel(_tokenId)
        .call()
        .then((receipt) => {
          let levelArray = levelsSt;
          levelArray[_tokenId] = receipt;
          setLevelsSt(levelArray);
          let loadingArray = loadingLevel;
          loadingArray[_tokenId] = false;
          setLoadingLevel(loadingArray);
        });
    }
  };
  const setSelectStatus = async (tokenId) => {
    if (blockchain.account !== "" && blockchain.statusSmartContract !== null) {
      setStakingTime(0);
      await blockchain.statusSmartContract.methods
        .getStatuses(tokenId)
        .call()
        .then((receipt) => {
          setStatuses(receipt);
          setStatusSelect(tokenId);
        });
      await blockchain.stakeSmartContract.methods
        .stakingTime(tokenId)
        .call()
        .then((receipt) => {
          setStakingTime(receipt);
        });
    }
  };
  const selectAllStakes = () => {
    let picked = datasub.imageURI?.map((item) => item[3]) || [];
    picked = picked.filter((item) => item != null);
    const uniquePicked = [...new Set(picked)];
    if (stakeSelect.length == uniquePicked.length) {
      setStakeSelect([]);
    } else {
      setStakeSelect(uniquePicked);
    }
    dispatch(fetchData(blockchain.account));
  };

  const selectAllUnStakes = () => {
    let picked = datastake.imageURI?.map((item) => item[3]) || [];
    picked = picked.filter((item) => item != null);
    const uniquePicked = [...new Set(picked)];
    if (unstakeSelect.length == uniquePicked.length) {
      setUnStakeSelect([]);
    } else {
      setUnStakeSelect(uniquePicked);
    }
    dispatch(fetchData(blockchain.account));
  };

  const setSelectStake = (stakeId) => {
    let stakeArray = stakeSelect;
    if (stakeArray.includes(stakeId)) {
      stakeArray.splice(stakeArray.indexOf(stakeId), 1);
    } else {
      stakeArray.push(stakeId);
    }
    setStakeSelect(stakeArray);
    dispatch(fetchData(blockchain.account));
  };

  const setSelectUnStake = (stakeId) => {
    let stakeArray = unstakeSelect;
    if (stakeArray.includes(stakeId)) {
      stakeArray.splice(stakeArray.indexOf(stakeId), 1);
    } else {
      stakeArray.push(stakeId);
    }
    console.dir(stakeArray);
    setUnStakeSelect(stakeArray);
    dispatch(fetchData(blockchain.account));
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getDataBase = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase(blockchain.account));
    }
  };
  const getDataStake = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataStake(blockchain.account));
    }
  };
  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    getDataBase();
    getDataSub();
    getDataStake();
  }, [datasub.isApprovedForAll]);

  useEffect(() => {
    console.dir(statuses, stakingTime);
    getLevelChallenge(statuses, stakingTime);
  }, [statuses, stakingTime]);

  useEffect(() => {
    getData();
    getDataBase();
    getDataStake();
    getDataSub();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <Header config={CONFIG} headerFlag={false} />
      {CONFIG.MINTPHASE ? (
        <>
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
            image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
          >
            <ul class="bgtrans">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <MintTitle
              id="mint"
              style={{
                textAlign: "center",
              }}
            >
              {"STAKE"}
            </MintTitle>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              <AudioPlayer></AudioPlayer>
            </s.TextDescription>
            <ResponsiveWrapper flex={1} test>
              {blockchain.account === "" ||
                (blockchain.smartContract === null && (
                  <s.Container
                    flex={1}
                    jc={"center"}
                    ai={"center"}
                  ></s.Container>
                ))}
              <s.Container flex={2} jc={"center"} ai={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                ></s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect(lang));
                        getData();
                      }}
                      style={{ maxWidth: 300 }}
                    >
                      接続(connect)
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {true ? (
                      <div
                        style={{
                          transformStyle: "preserve-3d",
                          animation: "fadein 1s linear forwards",
                          opacity: "0",
                          animationDelay: "2s",
                        }}
                      >
                        <s.SpacerLarge />
                        <BoxList>
                          <Grid container direction="column">
                            <Grid item container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <s.TextTitle
                                  style={{
                                    textAlign: "left",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  LANG/言語
                                </s.TextTitle>
                                <s.SpacerSmall />
                                <div className="toggle-switch">
                                  <input
                                    id="toggleBox"
                                    className="toggle-input"
                                    type="checkbox"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      // e.preventDefault();
                                      changeLang();
                                    }}
                                  />
                                  <label
                                    htmlFor="toggleBox"
                                    className="toggle-label"
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </BoxList>
                        <s.SpacerSmall />

                        {datasub.isApprovedForAll ? (
                          <>
                            <BoxList>
                              <Grid container direction="column">
                                <Grid item container>
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <StyledButtonTab
                                      onClick={() => setDispFrame(0)}
                                      style={{
                                        backgroundColor:
                                          dispFrame === 0 ? "#fff" : "#000",
                                        color:
                                          dispFrame === 0 ? "#000" : "#fff",
                                      }}
                                    >
                                      保有
                                    </StyledButtonTab>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <StyledButtonTab
                                      onClick={() => setDispFrame(1)}
                                      style={{
                                        backgroundColor:
                                          dispFrame === 1 ? "#fff" : "#000",
                                        color:
                                          dispFrame === 1 ? "#000" : "#fff",
                                      }}
                                    >
                                      ステーキング中
                                    </StyledButtonTab>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <StyledButtonTab
                                      onClick={() => setDispFrame(2)}
                                      style={{
                                        backgroundColor:
                                          dispFrame === 2 ? "#fff" : "#000",
                                        color:
                                          dispFrame === 2 ? "#000" : "#fff",
                                      }}
                                    >
                                      ステータス
                                    </StyledButtonTab>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </BoxList>
                            <BoxList
                              style={{
                                display: dispFrame === 0 ? "flex" : "none",
                              }}
                            >
                              <Grid container direction="column">
                                <Grid item container>
                                  <Grid item xs={12} sm={12}>
                                    {database.unload ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {langData.displaymsg}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {langData.hodltitle}
                                          {datasub.balanceOf}
                                          {langData.tickets}
                                        </TitlesTitle>
                                      </>
                                    )}

                                    {database.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading..."}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {datasub.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading...Balance.Data"}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"ステーキングする神を選ぶ"}
                                        </TitlesTitle>
                                      </>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <StyledButtonWall
                                      onClick={(e) => {
                                        e.preventDefault();
                                        selectAllStakes();
                                        // getInit();
                                      }}
                                      sx={{
                                        width: 50,
                                      }}
                                    >
                                      {"全て選択"}
                                      <br />
                                      {langData.application}
                                    </StyledButtonWall>
                                  </Grid>
                                  <Grid item xs={8} sm={8}>
                                    <StyledButtonWall
                                      onClick={(e) => {
                                        e.preventDefault();
                                        staking();
                                        // getInit();
                                      }}
                                      sx={{
                                        width: 50,
                                      }}
                                    >
                                      {stakeSelect.length > 0 ? (
                                        <>{stakeSelect.join(",") + " - "}</>
                                      ) : (
                                        <></>
                                      )}
                                      {"ステーキング"}
                                      <br />
                                      {langData.application}
                                    </StyledButtonWall>
                                  </Grid>
                                  {datasub.imageURI != undefined
                                    ? datasub.imageURI?.map((imageURI) => (
                                        <Suspense fallback={<></>}>
                                          <Grid
                                            item
                                            xs={3}
                                            sm={2}
                                            key={imageURI[0]}
                                            style={{
                                              perspective: "500px",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: 1 / 1.25,
                                                height: 1 / 1,
                                                mx: "auto",
                                                font: 'bold 1em "Fira Sans",serif',
                                                display: "block",
                                                backgroundColor: "none",
                                                transformStyle: "preserve-3d",
                                                animation:
                                                  "fadein 1s linear forwards",
                                                opacity: "0",
                                                border:
                                                  stakeSelect[imageURI[0]] ===
                                                  true
                                                    ? "solid 2px #fff"
                                                    : "none",
                                                animationDelay: "2s",
                                                "&:hover": {
                                                  backgroundColor: "red",
                                                  transition: "400ms",
                                                  opacity: [0.9, 0.8, 0.7],
                                                },
                                              }}
                                              onClick={() =>
                                                setSelectStake(imageURI[3])
                                              }
                                            >
                                              <LazyAndPreloadImage
                                                alt={"logo"}
                                                src={imageURI[1]}
                                                selected={
                                                  stakeSelect.includes(
                                                    imageURI[3]
                                                  ) == false
                                                }
                                              />
                                              <>
                                                {stakeSelect.includes(
                                                  imageURI[3]
                                                ) ? (
                                                  <>
                                                    <StyledTitlesCover
                                                      alt={"logo"}
                                                      src={
                                                        "/config/images/choice.png"
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>

                                              <StyledLink>
                                                <StyledTitlesName>
                                                  {imageURI[2]}
                                                </StyledTitlesName>
                                              </StyledLink>
                                            </Box>
                                          </Grid>
                                        </Suspense>
                                      ))
                                    : ""}
                                </Grid>
                              </Grid>
                              <s.SpacerLarge />
                            </BoxList>

                            <BoxList
                              style={{
                                display: dispFrame === 1 ? "flex" : "none",
                              }}
                            >
                              <Grid container direction="column">
                                <Grid item container>
                                  <Grid item xs={12} sm={12}>
                                    <TitlesTitle
                                      id="Titles"
                                      style={{
                                        textAlign: "center",
                                        perspective: "500px",
                                        color: "primary.main",
                                      }}
                                    >
                                      {"STAKED"}
                                    </TitlesTitle>
                                    {datastake.unload ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {langData.displaymsg}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {datastake.balanceOf > 0 ? (
                                            <>
                                              {langData.hodltitle}
                                              {datastake.balanceOf}
                                              {langData.tickets}
                                            </>
                                          ) : (
                                            <>
                                              {
                                                "ステーキング中の神はおりません。"
                                              }
                                            </>
                                          )}
                                        </TitlesTitle>
                                      </>
                                    )}
                                    {datastake.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading..."}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {datasub.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading...Balance.Data"}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <StyledButtonWall
                                      onClick={(e) => {
                                        e.preventDefault();
                                        selectAllUnStakes();
                                        // getInit();
                                      }}
                                    >
                                      {"全て選択"}
                                      {langData.application}
                                    </StyledButtonWall>
                                  </Grid>
                                  <Grid item xs={8} sm={8}>
                                    <StyledButtonWall
                                      onClick={(e) => {
                                        e.preventDefault();
                                        unstaking();
                                        // getInit();
                                      }}
                                    >
                                      {unstakeSelect.length > 0 ? (
                                        <>{unstakeSelect.join(",") + " - "}</>
                                      ) : (
                                        <></>
                                      )}

                                      {"STAKE解除"}
                                      {langData.application}
                                    </StyledButtonWall>
                                  </Grid>

                                  {datastake.imageURI != undefined
                                    ? datastake.imageURI?.map((imageURI) => (
                                        <Suspense fallback={<></>}>
                                          <Grid
                                            item
                                            xs={6}
                                            sm={2}
                                            key={imageURI[0]}
                                            style={{
                                              perspective: "500px",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: 1 / 1.25,
                                                height: 1 / 1,
                                                mx: "auto",
                                                display: "block",
                                                backgroundColor: "none",
                                                transformStyle: "preserve-3d",
                                                animation:
                                                  "fadein 1s linear forwards",
                                                opacity: "0",
                                                animationDelay: "2s",
                                                "&:hover": {
                                                  backgroundColor: "red",
                                                  transition: "400ms",
                                                  opacity: [0.9, 0.8, 0.7],
                                                },
                                              }}
                                              onClick={() =>
                                                setSelectUnStake(imageURI[3])
                                              }
                                            >
                                              <LazyAndPreloadImage
                                                alt={"logo"}
                                                src={imageURI[1]}
                                                selected={
                                                  unstakeSelect.includes(
                                                    imageURI[3]
                                                  ) == false
                                                }
                                              />
                                              <>
                                                {unstakeSelect.includes(
                                                  imageURI[3]
                                                ) ? (
                                                  <>
                                                    <StyledTitlesCover
                                                      alt={"logo"}
                                                      src={
                                                        "/config/images/choice.png"
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                              <StyledLink
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  getLevel(imageURI[3]);
                                                }}
                                              >
                                                <StyledTitlesName
                                                  style={{
                                                    display: "flex",
                                                    gap: 3,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {imageURI[2]}
                                                  {levels[imageURI[3]] ? (
                                                    <></>
                                                  ) : (
                                                    <button
                                                      style={{
                                                        border:
                                                          "solid #fff 1px",
                                                        margin: 3,
                                                        padding: "0 5px",
                                                        height: "min-content",
                                                      }}
                                                    >
                                                      check
                                                    </button>
                                                  )}
                                                  {loadingLevel[imageURI[3]] &&
                                                    "Loading..."}
                                                  {levels[imageURI[3]] &&
                                                    `|可能Lv.${
                                                      levels[imageURI[3]]
                                                    }`}
                                                  {levelsSt[imageURI[3]] &&
                                                    `|現在Lv.${
                                                      levelsSt[imageURI[3]]
                                                    }`}
                                                </StyledTitlesName>
                                              </StyledLink>
                                            </Box>
                                          </Grid>
                                        </Suspense>
                                      ))
                                    : ""}
                                </Grid>
                              </Grid>
                              <s.SpacerLarge />
                            </BoxList>

                            <BoxList
                              style={{
                                display: dispFrame === 2 ? "flex" : "none",
                              }}
                            >
                              <Grid container direction="column">
                                <Grid item container>
                                  <Grid item xs={12} sm={12}>
                                    {database.unload ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {langData.displaymsg}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {langData.hodltitle}
                                          {datasub.balanceOf}
                                          {langData.tickets}
                                        </TitlesTitle>
                                      </>
                                    )}

                                    {database.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading..."}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {datasub.loading ? (
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"Loading...Balance.Data"}
                                        </TitlesTitle>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                      <>
                                        <TitlesTitle
                                          id="Titles"
                                          style={{
                                            textAlign: "center",
                                            perspective: "500px",
                                            color: "primary.main",
                                          }}
                                        >
                                          {"ステータスを確認する神を選ぶ"}
                                        </TitlesTitle>
                                      </>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={6} sm={4}>
                                    <>
                                      {statusSelect !== false ? (
                                        <Box
                                          sx={{
                                            width: 1 / 1.25,
                                            height: 1 / 1,
                                            mx: "auto",
                                            font: 'bold 1em "Fira Sans",serif',
                                            display: "block",
                                            backgroundColor: "none",
                                            transformStyle: "preserve-3d",
                                            animation:
                                              "fadein 1s linear forwards",
                                            opacity: "0",
                                            animationDelay: "2s",
                                            "&:hover": {
                                              backgroundColor: "red",
                                              transition: "400ms",
                                              opacity: [0.9, 0.8, 0.7],
                                            },
                                          }}
                                        >
                                          <LazyAndPreloadImage
                                            alt={"logo"}
                                            src={
                                              datasub.imageURI[statusSelect][1]
                                            }
                                            selected={true}
                                          />

                                          <StyledLink target={"_blank"}>
                                            <StyledTitlesName>
                                              {`${
                                                datasub.imageURI[
                                                  statusSelect
                                                ][2]
                                              } Lv.${
                                                statuses[0]
                                              } Exp.${stakingTime}Pt Status.${
                                                statuses[2] * 10
                                              }`}
                                            </StyledTitlesName>
                                            <StyledTitlesName>
                                              {canLvUp ? (
                                                <>
                                                  {`レベルアップ可能`}
                                                  <StyledButtonWall
                                                    disabled={
                                                      claimingNft ? 1 : 0
                                                    }
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      levelUp(
                                                        datasub.imageURI[
                                                          statusSelect
                                                        ][3]
                                                      );
                                                    }}
                                                  >
                                                    {"レベルUP!"}
                                                  </StyledButtonWall>
                                                </>
                                              ) : (
                                                `必要：${pointRequire}Pt`
                                                // (約：${Math.ceil(
                                                //   pointRequire / (60 * 60 * 24)
                                                // )}日)
                                              )}
                                            </StyledTitlesName>
                                          </StyledLink>
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sm={8}
                                    style={{
                                      display: "flex",
                                      flexFlow: "wrap",
                                    }}
                                  >
                                    {datasub.imageURI != undefined
                                      ? datasub.imageURI?.map((imageURI) => (
                                          <Suspense fallback={<></>}>
                                            <Grid
                                              item
                                              xs={6}
                                              sm={2}
                                              key={imageURI[0]}
                                              style={{
                                                perspective: "500px",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  width: 1 / 1.25,
                                                  height: "min-content",
                                                  mx: "auto",
                                                  font: 'bold 1em "Fira Sans",serif',
                                                  display: "block",
                                                  backgroundColor: "none",
                                                  transformStyle: "preserve-3d",
                                                  animation:
                                                    "fadein 1s linear forwards",
                                                  opacity: "0",
                                                  border:
                                                    stakeSelect[imageURI[0]] ===
                                                    true
                                                      ? "solid 2px #fff"
                                                      : "none",
                                                  animationDelay: "2s",
                                                  "&:hover": {
                                                    backgroundColor: "red",
                                                    transition: "400ms",
                                                    opacity: [0.9, 0.8, 0.7],
                                                  },
                                                }}
                                                onClick={() =>
                                                  setSelectStatus(imageURI[3])
                                                }
                                              >
                                                <LazyAndPreloadImage
                                                  alt={"logo"}
                                                  src={imageURI[1]}
                                                  selected={true}
                                                />

                                                <StyledLink>
                                                  <StyledTitlesName>
                                                    {imageURI[2]}
                                                  </StyledTitlesName>
                                                </StyledLink>
                                              </Box>
                                            </Grid>
                                          </Suspense>
                                        ))
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <s.SpacerLarge />
                            </BoxList>
                          </>
                        ) : (
                          <>
                            {datasub.isApprovedForAll == null ? (
                              <StyledButtonLoading
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                {"Loading..."}
                              </StyledButtonLoading>
                            ) : (
                              <StyledButtonWall
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  approveAll(); // getInit();
                                }}
                              >
                                {"ステーキング許可"}
                              </StyledButtonWall>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <s.SpacerMedium />
              </s.Container>
              <s.SpacerLarge />
              {blockchain.account === "" ||
                (blockchain.smartContract === null && (
                  <s.Container
                    flex={1}
                    jc={"center"}
                    ai={"center"}
                  ></s.Container>
                ))}
            </ResponsiveWrapper>
          </s.Container>

          <Footer config={CONFIG} />
        </>
      ) : (
        <></>
      )}
    </s.Screen>
  );
}

export default Home;
